import { Route, Switch, Redirect } from "react-router-dom";
import React, { Suspense, lazy, useEffect, useState } from "react";
import { Row, Col, Button, Alert } from "reactstrap";
import Loader from "react-loaders";
import cx from "classnames";

import AppHeader from "../AppHeader";
import AppSidebar from "../AppSidebar";

import { getInfoUser } from "../../services/auth";
import api from '../../services/api';
import mapRoutes from '../../services/mapRoutes';
import menuPermissions from "../../services/menuPermissions";
import { ErrorToast } from "./Components/Toast";
import NFeDisablement from "../../Pages/NFeDisablement";
import Chatbot from "./Components/Chatbot";
import WhatsAppSupport from "./Components/WhatsAppSupport";
import { set } from "date-fns";

const Dashboard = lazy(() => import("../../Pages/Dashboard"));
const FirstSteps = lazy(() => import("../../Pages/FirstSteps"));
const Product = lazy(() => import("../../Pages/Product"));
const Stock = lazy(() => import("../../Pages/Stock"));
const Inventory = lazy(() => import("../../Pages/Inventory"));
const Company = lazy(() => import("../../Pages/Company"));
const CompanySetting = lazy(() => import("../../Pages/CompanySetting"));
const Order = lazy(() => import("../../Pages/Order"));
const ServiceOrder = lazy(() => import("../../Pages/ServiceOrder"));
const StorageUsage = lazy(() => import("../../Pages/StorageUsage"));
const Contract = lazy(() => import("../../Pages/Contract"));
const Person = lazy(() => import("../../Pages/Person"));
const Cfop = lazy(() => import("../../Pages/Cfop"));
const Ncm =  lazy(() =>  import("../../Pages/Ncm"));
const Account =  lazy(() =>  import("../../Pages/Account"));
const Purchase = lazy(() => import("../../Pages/Purchase"));
const Installment = lazy(() => import("../../Pages/Installment"));
const FinancialOperationNature = lazy(() => import("../../Pages/FinancialOperationNature"));
const Category =  lazy(() =>  import("../../Pages/Category"));
const Pdv =  lazy(() =>  import("../../Pages/Pdv"));
const Users =  lazy(() =>  import("../../Pages/User"));
const Extract =  lazy(() =>  import("../../Pages/Extract"));
const Compensate =  lazy(() =>  import("../../Pages/Compensate"));
const Remittance =  lazy(() =>  import("../../Pages/RemittanceBank"));
const Return =  lazy(() =>  import("../../Pages/ReturnBank"));
const Historic =  lazy(() =>  import("../../Pages/HistoricLog"));
const DailyFlow =  lazy(() =>  import("../../Pages/DailyFlow"));
const MonthlyFlow =  lazy(() =>  import("../../Pages/MonthlyFlow"));
const CustomerOrder =  lazy(() =>  import("../../Pages/CustomerOrder"));
const SellerOrder =  lazy(() =>  import("../../Pages/SellerOrder"));
const SellerComission =  lazy(() =>  import("../../Pages/SellerComission"));
const BestProduct =  lazy(() =>  import("../../Pages/BestProduct"));
const ProductCustomer =  lazy(() =>  import("../../Pages/ProductCustomer"));
const ProductGroups =  lazy(() =>  import("../../Pages/ProductGroups"));
const Received =  lazy(() =>  import("../../Pages/Received"));
const Payed =  lazy(() =>  import("../../Pages/Payed"));
const CustomerList =  lazy(() =>  import("../../Pages/CustomerList"));
const TurningStock =  lazy(() =>  import("../../Pages/TurningStock"));
const PositionPerson =  lazy(() =>  import("../../Pages/PositionPerson"));
const DRE =  lazy(() =>  import("../../Pages/DRE"));
const DashboardDre =  lazy(() =>  import("../../Pages/DashboardDre"));
const ProfitSimulator =  lazy(() =>  import("../../Pages/ProfitSimulator"));
const ImportData =  lazy(() =>  import("../../Pages/ImportData"));
const NFe =  lazy(() =>  import("../../Pages/NFe"));
const NFSe =  lazy(() =>  import("../../Pages/NFSe"));
const NFCe =  lazy(() =>  import("../../Pages/NFCe"));
const MDFe =  lazy(() =>  import("../../Pages/MDFe"));
const NFConta =  lazy(() =>  import("../../Pages/NFConta"));
const Profile =  lazy(() =>  import("../../Pages/Profile"));
const Plan = lazy(() => import("../../Pages/Plan"));
const Ticket = lazy(() => import("../../Pages/Ticket"));
const Suggestion = lazy(() => import("../../Pages/Suggestion"));
const NotificationView = lazy(() => import("../../Pages/Notification/View"));
const AccessSupport = lazy(() => import("../../Pages/AccessSupport"));
const Log = lazy(() => import("../../Pages/Log"));
const Contador = lazy(() => import("../../Pages/Contador"));
const PurchaseInvoice = lazy(() => import("../../Pages/PurchaseInvoice"));
const Tour = lazy(() => import("../../Pages/Tour"));
const InstructionsSetup = lazy(() => import("../../Pages/InstructionsSetup"));
const BusinessSectorSetup = lazy(() => import("../../Pages/BusinessSectorSetup"));
const AccountantSetup = lazy(() => import("../../Pages/AccountantSetup"));
const CostCenter = lazy(() => import("../../Pages/CostCenter"));
const Projects = lazy(() => import("../../Pages/Projects"));
const HomePage =  lazy(() =>  import("../../Pages/HomePage"));
const Mailbox =  lazy(() =>  import("../../Pages/Mailbox"));
const ReportAutomation =  lazy(() =>  import("../../Pages/ReportAutomation"));
const ReportAutomationWhatsApps =  lazy(() =>  import("../../Pages/ReportAutomationWhatsApps"));
const ReportAutomationEmails =  lazy(() =>  import("../../Pages/ReportAutomationEmails"));

const AppMain = (props) => {
  const [showChatbot, setShowChatbot] = useState(false);
  const accessValidate = (route, user) => {
    let routeAdmin = ['/logs', '/meu-plano']

    const handleCheckPermissionPlan = (permission) => {
      let permissions = Object.values(user.plan.permissions);
      permissions = permissions.filter(p => {
        return p.includes(permission);
      });

      return permissions.length > 0;
    }

    if (routeAdmin.includes(route) && user.level !== 1) {
      return false;
    } else if (!menuPermissions()[`/#${route}`]) {
      return true;
    } else if (!user.plan && user.company.test && user.permissions.includes(menuPermissions()[`/#${route}`]) && (!user.company.test_permissions || user.company.test_permissions.includes(menuPermissions()[`/#${route}`]))) {
      return true;
    } else if (user.plan && user.permissions.includes(menuPermissions()[`/#${route}`]) && handleCheckPermissionPlan(menuPermissions()[`/#${route}`])) {
      return true
    }

    return false;
  }

  useEffect(() => {
    let user = getInfoUser();
    if (
      user &&
      user.email &&
      ['@nfservice.com.br', '@fuganholisistemas.com.br'].some((domain) => user.email.includes(domain))
    ) {
      setShowChatbot(true);
    }
    props.history.listen((location) => {
      if (user.company_blocked && !['/bloqueado', '/filiais'].includes(location.pathname)) {
        props.history.push('/bloqueado');
      } else {
        if (accessValidate(location.pathname, user)) {
          let route = location.pathname.split('/')
          if (((route.length === 3 && isNaN(route[2])) || route.length < 3) && mapRoutes[route[1]]) {
            let data = {
              event: 'access',
              additional_details: {}
            }

            if (route.length === 2) {
              data.additional_details['Ação'] = mapRoutes[route[1]][0] || ''
            } else if (mapRoutes[route[1]][route[2]]) {
              data.additional_details['Ação'] = mapRoutes[route[1]][route[2]]
            }

            if (Object.keys(data.additional_details).length > 0) {
              api.post('logs', data)
            }
          }
        } else {
          ErrorToast({ placeholder: 'Você não possui permissão de acesso à essa página! Caso necessite, entre em contato com o administrador do sistema!' })
          props.history.push('/');
        }
      }
    });
  }, []);

  return (
    <Suspense fallback={
      <div className="loader-container">
        <div className="loader-container-inner">
          <div className="text-center">
            <Loader show={true} type="ball-spin-fade-loader"/>
          </div>
            <h6 className="mt-5">
              Carregando...
            </h6>
        </div>
      </div>
    }>
      <AppHeader {...props} />
      <div className="app-main">
        {props.width <= 991 && <AppSidebar />}
        <div className="app-main__outer">
          <div className="container-fluid" hidden={!props.showAlertTrialPeriod}>
            <Row style={{textAlign: "center"}}>
              <Col style={{paddingInline: 0}}>
                <Alert className="alert-warning mb-0" toggle={props.toggleAlertTrialPeriod}>
                  Seu período de <b>teste termina {props.testTerm}</b>! Confira os planos disponíveis para contratação
                  <Button className="ml-2" type="button" color="bgsecondary" onClick={() => props.history.push('/meu-plano?consultar-planos=true')}>Planos</Button>
                </Alert>
              </Col>
            </Row>
          </div>
          <div className={cx(
            "app-main__inner",
            {"col-md-10 offset-md-1": props.width > 991}
          )}>
              <Switch>
                  {/** Aqui deve ser definidas as rotas do sistema */}
                  {
                    props.isTrialPeriod ?
                    <Route exact path="/" render={({match}) => <FirstSteps {...props} match={match} />
                    } /> :
                    <Route exact path="/" render={({match}) => <HomePage {...props} match={match} />
                    } />
                  }
                  <Route path="/dados-empresa" render={({match}) => <Company {...props} match={match} />
                  } />
                  <Route path="/config-empresa" render={({match}) => <CompanySetting {...props} match={match} />
                  } />
                  <Route path="/ncms" render={({match}) => <Ncm {...props} match={match} />
                  } />
                  <Route path="/cfops" render={({match}) => <Cfop {...props} match={match} />
                  } />
                  <Route path="/usuarios" render={({match}) => <Users {...props} match={match} />
                  } />
                  <Route path="/perfil" render={({match}) => <Profile {...props} match={match} />
                  } />
                  <Route path="/importa-dados" render={({match}) => <ImportData {...props} match={match} />
                  } />
                  <Route path="/vendas/clientes" render={({match}) => <Person {...props} match={match} />
                  } />
                  <Route path="/vendas/produtos" render={({match}) => <Product {...props} match={match} />
                  } />
                  <Route path="/vendas/transportadoras" render={({match}) => <Person {...props} match={match} />
                  } />
                  <Route path="/vendas/vendedores" render={({match}) => <Person {...props} match={match} />
                  } />
                  <Route path="/vendas/estoque" render={({match}) => <Stock {...props} match={match} />
                  } />
                  <Route path="/vendas/inventario" render={({match}) => <Inventory {...props} match={match} />
                  } />
                  <Route path="/vendas/pdv" render={({match}) => <Pdv {...props} match={match} />
                  } />
                  <Route path="/vendas/contratos" render={({match}) => <Contract {...props} match={match} />
                  } />
                  <Route path="/vendas/inutilizar-notas" render={({match}) => <NFeDisablement {...props} match={match} />
                  } />
                  <Route path="/vendas/notas-fiscais" render={({match}) => <NFe {...props} match={match} />
                  } />
                  <Route path="/vendas/notas-fiscais-consumidor" render={({match}) => <NFCe {...props} match={match} />
                  } />
                  <Route path="/vendas/manifestos-eletronicos" render={({match}) => <MDFe {...props} match={match} />
                  } />
                  <Route path="/vendas/grupo-produtos" render={({match}) => <ProductGroups {...props} match={match} />
                  } />
                  <Route path="/vendas" render={({match}) => <Order {...props} match={match} />
                  } />
                  <Route path="/servicos/ordem-servico" render={({match}) => <ServiceOrder {...props} match={match} />
                  } />
                  <Route path="/servicos/notas-fiscais-servico" render={({match}) => <NFSe {...props} match={match} />
                  } />
                  <Route path="/servicos" render={({match}) => <Product {...props} match={match} />
                  } />
                  <Route path="/compras/fornecedores" render={({match}) => <Person {...props} match={match} />
                  } />
                  <Route path="/compras/produtos" render={({match}) => <Product {...props} match={match} />
                  } />
                  <Route path="/compras/estoque" render={({match}) => <Stock {...props} match={match} />
                  } />
                  <Route path="/compras/inventario" render={({match}) => <Inventory {...props} match={match} />
                  } />
                  <Route path="/compras/impostos" render={({match}) => <Person {...props} match={match} />
                  } />
                  <Route path="/compras/contratos" render={({match}) => <Contract {...props} match={match} />
                  } />
                  <Route path="/compras/notas-fiscais" render={({match}) => <PurchaseInvoice {...props} match={match} />
                  } />
                  <Route path="/compras/grupo-produtos" render={({match}) => <ProductGroups {...props} match={match} />
                  } />
                  <Route path="/compras" render={({match}) => <Purchase {...props} match={match} />
                  } />
                  <Route path="/financeiro/contas-receber" render={({match}) => <Installment {...props} match={match} />
                  } />
                  <Route path="/financeiro/contas" render={({match}) => <Account {...props} match={match} />
                  } />
                  <Route path="/financeiro/nfconta" render={({match}) => <NFConta {...props} match={match} />
                  } />
                  <Route path="/financeiro/contas-pagar" render={({match}) => <Installment {...props} match={match} />
                  } />
                  <Route path="/financeiro/natureza-operacao" render={({match}) => <FinancialOperationNature {...props} match={match} />
                  } />
                  <Route path="/financeiro/extrato" render={({match}) => <Extract {...props} match={match} />
                  } />
                  <Route path="/financeiro/conciliacao" render={({match}) => <Compensate {...props} match={match} />
                  } />
                  <Route path="/financeiro/remessas" render={({match}) => <Remittance {...props} match={match} />
                  } />
                  <Route path="/financeiro/retornos" render={({match}) => <Return {...props} match={match} />
                  } />
                  <Route path="/financeiro/categorias" render={({match}) => <Category {...props} match={match} />
                  } />
                  <Route path="/financeiro/historico" render={({match}) => <Historic {...props} match={match} />
                  } />
                  <Route path="/financeiro/centro-custo" render={({match}) => <CostCenter {...props} match={match} />
                  } />
                  <Route path="/financeiro/projetos" render={({match}) => <Projects {...props} match={match} />
                  } />
                  <Route path="/financeiro/caixa-entrada" render={({match}) => <Mailbox {...props} match={match} />
                  } />
                  <Route path="/relatorios/fluxo-diario" render={({match}) => <DailyFlow {...props} match={match} />
                  } />
                  <Route path="/relatorios/fluxo-mensal" render={({match}) => <MonthlyFlow {...props} match={match} />
                  } />
                  <Route path="/relatorios/vendas-cliente" render={({match}) => <CustomerOrder {...props} match={match} />
                  } />
                  <Route path="/relatorios/vendas-vendedor" render={({match}) => <SellerOrder {...props} match={match} />
                  } />
                  <Route path="/relatorios/comissao-vendedor" render={({match}) => <SellerComission {...props} match={match} />
                  } />
                  <Route path="/relatorios/mais-vendidos" render={({match}) => <BestProduct {...props} match={match} />
                  } />
                  <Route path="/relatorios/produtos-cliente" render={({match}) => <ProductCustomer {...props} match={match} />
                  } />
                  <Route path="/relatorios/analise-recebimentos" render={({match}) => <Received {...props} match={match} />
                  } />
                  <Route path="/relatorios/analise-pagamentos" render={({match}) => <Payed {...props} match={match} />
                  } />
                  <Route path="/relatorios/clientes" render={({match}) => <CustomerList {...props} match={match} />
                  } />
                  <Route path="/relatorios/giro-estoque" render={({match}) => <TurningStock {...props} match={match} />
                  } />
                  <Route path="/relatorios/posicao-contas" render={({match}) => <PositionPerson {...props} match={match} />
                  } />
                  <Route path="/relatorios/dre" render={({match}) => <DRE {...props} match={match} />
                  } />
                  <Route path="/relatorios/dashboard-dre" render={({match}) => <DashboardDre {...props} match={match} />
                  } />
                  <Route path="/relatorios/dashboard-resumo" render={({match}) => <Dashboard {...props} match={match} />
                  } />
                  <Route path="/relatorios/simulador-lucros" render={({match}) => <ProfitSimulator {...props} match={match} />
                  } />
                  <Route path="/relatorios/automacao" render={({match}) => <ReportAutomation {...props} match={match} />
                  } />
                  <Route path="/relatorios/whatsapps" render={({match}) => <ReportAutomationWhatsApps {...props} match={match} />
                  } />
                  <Route path="/relatorios/emails" render={({match}) => <ReportAutomationEmails {...props} match={match} />
                  } />
                  <Route path="/tickets" render={({match}) => <Ticket {...props} match={match} />
                  } />
                  <Route path="/sugestoes" render={({match}) => <Suggestion {...props} match={match} />
                  } />
                  <Route path="/notificacoes/visualiza/:id" render={({match}) => <NotificationView {...props} match={match} />
                  } />
                  <Route path="/bloqueado" render={({match}) => <Plan {...props} match={match} />
                  } />
                  <Route path="/meu-contador" render={({match}) => <Contador {...props} match={match} />
                  } />
                  <Route path="/meu-plano" render={({match}) => <Plan {...props} match={match} />
                  } />
                  <Route path="/acesso-suporte" render={({match}) => <AccessSupport {...props} match={match} />
                  } />
                  <Route path="/logs" render={({match}) => <Log {...props} match={match} />
                  } />
                  <Route path="/tutoriais" render={({match}) => <Tour {...props} match={match} />
                  } />
                  <Route path="/configuracao-inicial" render={({match}) => <InstructionsSetup {...props} match={match} />
                  } />
                  <Route path="/ramo-da-empresa" render={({match}) => <BusinessSectorSetup {...props} match={match} />
                  } />
                  <Route path="/configuracao-inicial-meu-contador" render={({match}) => <AccountantSetup {...props} match={match} />
                  } />
                  <Route path="/armazenamento" render={({match}) => <StorageUsage {...props} match={match} />
                  } />
                  <Route render={() => <Redirect to="/" />} />
              </Switch>
              {
                showChatbot &&
                <Chatbot {...props} />
              }
              <WhatsAppSupport {...props} />
          </div>
        </div>
      </div>
    </Suspense>
  )
};

export default AppMain;
