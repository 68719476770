import React, { useState } from 'react';

const WhatsAppSupport = () => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <a
      className='whatsapp-widget-container'
      href="https://wa.me/551935474200?text=Ol%C3%A1!%20Estou%20precisando%20de%20suporte%20no%20sistema%20EASY"
      target="_blank"
      rel="noopener noreferrer"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={{
        position: 'fixed',
        bottom: '80px',
        right: '20px',
        backgroundColor: isHovered ? '#1DA851' : '#25D366',
        color: 'white',
        padding: '10px 10px',
        borderRadius: '50px',
        textDecoration: 'none',
        fontFamily: 'Arial, sans-serif',
        display: 'flex',
        alignItems: 'center',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
        zIndex: 1000,
      }}
    >
      <img
        src="https://upload.wikimedia.org/wikipedia/commons/5/5e/WhatsApp_icon.png"
        alt="WhatsApp Icon"
        style={{
          width: '30px',
          height: '30px',
        }}
      />
    </a>
  );
};

export default WhatsAppSupport;
