import React, { useEffect, useRef, useState } from 'react'

import * as NFBot from 'react-chat-widget'
import moment from 'moment'
import { Button } from "reactstrap";
import { FaAt, FaPhoneAlt }  from 'react-icons/fa'
import { getInfoUser } from '../../../../services/auth';

import api from '../../../../services/api';
import { Link } from 'react-router-dom';

const Chatbot = ({ history }) => {
  const user = getInfoUser();
  const [ questions, setQuestions ] = useState([]);
  const refQuestions = useRef();
  refQuestions.current = questions

  const contactsSupport = [
    {
      type: 'phone',
      label: 'Telefone:',
      contact: '0800-777-4002'
    }
  ]

  const handleNewUserMessage = newMessage => {
    if (!newMessage || !newMessage.trim()) {
      return
    }

    let newQuestions = [...refQuestions.current]
    newQuestions.push(`<p><b>${user.email}: </b>${newMessage}</p>`)
    setQuestions(newQuestions);

    NFBot.toggleMsgLoader();

    api.post('bot/question', {
      question: newMessage
    })
    .then(response => handleResponseMessage(response.data))
    .catch(e => {
      console.log(e)
      let msg = e.errors ? e.errors.join('<br />') : 'Desculpe, mas não consegui processar sua questão, tente novamente mais tarde!'
      handleResponseMessage({ answer: msg })
    }).finally(() => NFBot.toggleMsgLoader())
  }

  const handleOpenTicket = () => {
    NFBot.toggleMsgLoader()

    api.post('bot/ticket', {
      question: refQuestions.current.join("\r\n")
    })
    .then(response => handleResponseMessage(response.data))
    .catch(e => {
      console.log(e)
      let msg = e.errors ? e.errors.join('<br />') : 'Desculpe, mas não consegui processar sua questão, entre em contato com o suporte pelo 0800 ou WhatsApp'
      handleResponseMessage({ answer: msg })
    })
  }

  const handleResponseMessage = data => {
    let newQuestions = [...refQuestions.current]
    if (data.answer) {
      NFBot.toggleMsgLoader();
      handleCustomMessage(<span dangerouslySetInnerHTML={{__html: data.answer}}></span>)
      newQuestions.push(`<p><b>Bot: </b>${data.answer}</p>`)
      NFBot.toggleMsgLoader();
    }
    if (data.list) {
      if (Object.keys(data.list).length > 1) {
        NFBot.toggleMsgLoader();
        NFBot.addResponseMessage('Sobre que assunto deseja falar?');
        let keys = Object.keys(data.list)
        let buttons = []
        for (let i of keys) {
          buttons.push(<Button onClick={() => handleClickButton(() => {
            handleResponseMessage({
              buttonNotResolved: data.buttonNotResolved,
              list: { 0: data.list[i]
            }});
          }, i)} className='mr-2' color="primary" outline>{i}</Button>)
        }

        NFBot.toggleMsgLoader();
        setQuestions(newQuestions)
        handleButton(buttons)
        return
      }

      NFBot.toggleMsgLoader();
      NFBot.addResponseMessage('Encontrei os seguintes artigos sobre o tema:');

      let li = []
      for (let i in data.list[0]) {
        li.push(<li key={i}><Link to={() => {}} onClick={() => openLinkAndCloseWidget(data.list[0][i].link)} className="text-primary">{data.list[0][i].title}</Link></li>)
      }

      NFBot.renderCustomComponent(() => <ul>{li}</ul>, {})
      newQuestions.push(`<li><ul>${data.list[0].map(list => list.title).join('</ul><ul>')}</ul></li>`);
      NFBot.toggleMsgLoader();
    }
    if (data.contacts) {
      NFBot.toggleMsgLoader();
      let contacts = []
      for (let i in data.contacts) {
        let type = data.contacts[i].type === 'email' ? 'mailto:' : 'tel:'
        let icon = data.contacts[i].type === 'email' ? <FaAt /> : <FaPhoneAlt />
        contacts.push(<p><a href={`${type}${data.contacts[i].contact}`}>{icon} {data.contacts[i].contact}</a></p>)
      }
      NFBot.toggleMsgLoader();

      handleCustomMessage(contacts);
    }
    if (data.support) {
      handleSupport()
    }
    if (data.buttonTickets) {
      handleButton(<Button className="mr-2" onClick={() => handleClickButton(() =>{
        NFBot.toggleWidget();
        history.push('/tickets')
      }, 'Acessar meus tickets')} color="primary" outline>Acessar meus tickets</Button>)
    }
    if (data.buttonNotResolved) {
      handleButton(<Button className="mr-2" onClick={() => handleClickButton(() => {
        handleResponseMessage({answer: 'Nesse caso, peço que por gentileza entre em contato com o suporte através dos contatos abaixo ou abra um ticket para que um de nossos agentes entre em contato com você!', support: true})
      }, 'Ainda preciso de ajuda')} color="primary" outline>Ainda preciso de ajuda</Button>)
    }

    setQuestions(newQuestions)
  }

  const handleButton = (buttons, props = {}) => {
    if (!buttons || buttons.length === 0) {
      return
    }

    NFBot.toggleMsgLoader();

    buttons = <div className="rcw-message ">{buttons}</div>

    NFBot.renderCustomComponent(() => buttons, props)
    NFBot.toggleMsgLoader();
  }

  const handleClickButton = (action, message) => {
    NFBot.addUserMessage(message)
    let newQuestions = [...refQuestions.current]
    newQuestions.push(`<p><b>${user.email}: </b>${message}</p>`)
    setQuestions(newQuestions);
    NFBot.toggleMsgLoader();
    setTimeout(() => {
      action();
      NFBot.toggleMsgLoader();
    }, 1000);
  }

  const handleCustomMessage = (message, props = {}) => {
    if (!message) {
      return
    }

    NFBot.toggleMsgLoader();
    message = <div className="rcw-message ">
      <div className="rcw-response">
        <div className="rcw-message-text">
          {message}
        </div>
        <span className="rcw-timestamp">{moment().format('hh:mm')}</span>
      </div>
    </div>

    NFBot.renderCustomComponent(() => message, props)
    NFBot.toggleMsgLoader();
  }

  const openLinkAndCloseWidget = url => {
    NFBot.toggleWidget();
    history.push(url);
  }

  const handleSupport = (props = {}) => {
    NFBot.toggleMsgLoader();
    let contacts = []
    for (let i in contactsSupport) {
      let type = contactsSupport[i].type === 'email' ? 'mailto:' : 'tel:'
      let icon = contactsSupport[i].type === 'email' ? <FaAt /> : <FaPhoneAlt />
      contacts.push(<p key={i}><a href={`${type}${contactsSupport[i].contact}`}>{icon} {contactsSupport[i].contact}</a></p>)
    }

    let message = <div className="rcw-message ">
      <div className="rcw-response">
        <div className="rcw-message-text">
          {contacts}
        </div>
        <Button className="mr-2 mt-2 mb-2" onClick={() => handleClickButton(handleOpenTicket, 'Abrir chamado')} color="primary" outline>Abrir chamado</Button>
        <span className="rcw-timestamp">{moment().format('hh:mm')}</span>
      </div>
    </div>

    NFBot.renderCustomComponent(() => message, props)
    NFBot.toggleMsgLoader();
  }

  useEffect(() => {
    NFBot.addResponseMessage(`Olá ${user.firstname}, tudo bem?! Caso necessite você pode tirar suas dúvidas através desse chat e tentarei lhe ajudar! 😊`);
    NFBot.markAllAsRead();
  }, []);

  return <NFBot.Widget
    handleNewUserMessage={handleNewUserMessage}
    title="Fuganholi Easy"
    subtitle="Utilize esse chat para tirar suas dúvidas"
    senderPlaceHolder="Qual sua dúvida?..."
    launcherOpenLabel="Fechar chat"
    launcherCloseLabel="Abrir chat"
    sendButtonAlt="Enviar"
    resizable={false}
    emojis={true}
    showBadge={true}
  />
}

export default Chatbot
