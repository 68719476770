import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { Button, Col, InputGroup, Input, Row, DropdownToggle, InputGroupButtonDropdown, DropdownMenu, Collapse, Card } from 'reactstrap';
import { FaSearch } from "react-icons/fa";
import { MdOutlineFilterAltOff } from 'react-icons/md';

const ButtonsIndex = ({
  onSearch,
  onNew,
  newDisabled,
  moreFilters,
  notButtons,
  notSearch,
  aditionalButtons,
  styleSearch,
  titleSearch,
  disabledInputSearch,
  onCleanFilters,
  textSearch,
  setTextSearch,
  handleInput,
}) => {
  const [dropDownOpen, setDropDownOpen] = useState(false);
  const [update, setUpdate] = useState(false);

  const handleEnter = (e) => {
    if (e.key === 'Enter' && onSearch !== undefined) {
      onSearch(textSearch);
    }
  }

  const handleClickSearch = useCallback(() => {
    if (onSearch !== undefined) {
      onSearch(textSearch);
    }
  }, [onSearch, textSearch]);

  const handleCleanFilters = () => {
    setTextSearch('')
    if (onCleanFilters) {
      onCleanFilters()
    }
    setUpdate(!update);
  }

  useEffect(() => handleClickSearch(), [update])

  return (
      <>
          <Row>
            <Col md={9}>
            { !notButtons &&
              <>
                  {
                    onNew &&
                    <Button id="btnNew" className="mb-2 mr-3" color="primary" onClick={onNew} disabled={newDisabled}>Novo</Button>
                  }
                  {aditionalButtons}
              </>
            }
            </Col>
            <Col md={3}>
            { !notSearch &&
              <>
                <InputGroup style={styleSearch}>
                  <Input id="inputSearch" disabled={disabledInputSearch} placeholder="Digite para buscar" title={titleSearch} value={textSearch} onInput={handleInput} onKeyDown={handleEnter} />
                  <InputGroupButtonDropdown addonType="append" isOpen={dropDownOpen} direction="down" toggle={() => {}}>
                    <Button dusk="search" color="light" onClick={handleClickSearch}>
                      <FaSearch color="var(--primary)" />
                    </Button>
                    {
                      moreFilters &&
                      <DropdownToggle dusk="filters" split color="light" onClick={() => setDropDownOpen(!dropDownOpen)}/>
                    }
                  </InputGroupButtonDropdown>
                </InputGroup>
              </>
            }
            </Col>
            <Col hidden={!moreFilters} md={12}>
              <Collapse isOpen={dropDownOpen}>
                <Card className="card-shadow-primary border mb-2" body  color="light">
                  { moreFilters }
                  <Row>
                    <Col>
                      <Button
                      style={{
                        float: 'right'
                      }}
                      outline
                      color="primary"
                      className="border-0"
                      onClick={handleCleanFilters}
                    >
                      <MdOutlineFilterAltOff size={18} className="mr-2"/>
                      Limpar Filtros
                    </Button>
                    </Col>
                  </Row>
                </Card>
              </Collapse>
            </Col>
          </Row>
          <br />
      </>
  )
}

export default ButtonsIndex;
